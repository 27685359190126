import styled, { css } from 'styled-components';

import { Icon } from './Icon';
import { Span } from './Generic';
import SC from './SC';

interface BadgeProps {
  brand?: string;
  outline?: boolean;
  size?: number;
}

const Badge = styled(Span)<SC<BadgeProps>>`
  ${({ sc: { brand, outline, size } = {}, theme }) => {
    const color = theme.getColor(brand || 'gray.200');

    return css`
      background: ${color};
      color: ${theme.getTitleColor(color)};
      display: inline-block;
      min-width: ${size ? `${size}px` : '1.7em'};
      height: 1.7em;
      line-height: 1.65;
      border-radius: 1000px;
      font-size: 0.875em;
      text-align: center;
      padding: 0 0.4em;

      ${size && css`
        width: ${size}px;
        height: ${size}px;
        line-height: ${size}px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        ${Icon} {
          margin: 0;
        }
      `}

      ${outline && css`
        background: transparent;
        border: 1px solid ${color};
        color: ${theme.getColor(brand || 'gray.900')};
      `}
    `;
  }}
`;

export default Badge;
