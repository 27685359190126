import React, { ReactNode, useCallback, useContext, useMemo, useRef } from 'react';

import SessionStorage from '../SessionStorage';

const generateSessionId = () => {
  const characters = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36));

  // Two positions are always the same, to detect bots that generate their own session IDs.
  // eslint-disable-next-line prefer-destructuring
  characters[12] = characters[4];

  return characters.join('');
};

export const getSessionId = () => {
  const sessionId = SessionStorage.getItem('session_id') || generateSessionId();

  SessionStorage.setItem('session_id', sessionId);

  return sessionId;
};

interface TrackingProviderProps {
  children: ReactNode;
  defaultPayload?: any;
}

interface TrackingContextValue {
  track: (name: string, payload?: any) => void;
}

const TrackingContext = React.createContext({} as TrackingContextValue);

export const TrackingProvider = ({ defaultPayload, children }: TrackingProviderProps) => {
  const timerRef = useRef<number>();
  const queueRef = useRef([]);

  const emptyQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      trackEvents({
        events: queueRef.current.map((trackingEvent) => ({
          ...trackingEvent,
          payload: JSON.stringify({
            ...defaultPayload,
            ...trackingEvent?.payload,
          }),
        })),
      });

      queueRef.current = [];
    }
  }, [queueRef, defaultPayload]);

  const track = useCallback((name: string, payload?: any) => {
    clearTimeout(timerRef.current);

    queueRef.current.push({
      name,
      payload,
      timestamp: Math.floor((new Date()).getTime() / 1000),
    });

    timerRef.current = window.setTimeout(emptyQueue, 5000);
  }, [timerRef, queueRef, emptyQueue]);

  const value = useMemo(() => ({ track }), [track]);

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};

/**
   * Sends the tracking events to the server using a basic XMLHttpRequest to cause the least
   * amount of overhead, so that this method can be called in clean-up functions such as
   * window.onbeforeunload().
   */
const trackEvents = (data: any) => {
  const request = new XMLHttpRequest();
  request.open('POST', '/api/track');
  request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  request.setRequestHeader('Atleta-Session-ID', getSessionId());
  request.send(JSON.stringify(data));
};

export const useTracking = () => useContext(TrackingContext);

export default undefined;
