import React, { ReactNode, useCallback, useEffect, useState } from 'react';

interface CookieConsentContextValue {
  cookieConsent: boolean | null;
  acceptCookies: () => void;
  denyCookies: () => void;
}

export const CookieConsentContext = React.createContext({} as CookieConsentContextValue);

const updateCookie = (value: boolean | null) => {
  if (value) {
    // Remember choice for 365 days
    document.cookie = 'cookie_consent=1;path=/;Max-Age=31536000';
  } else if (value === false) {
    // Remember choice for current session
    document.cookie = 'cookie_consent=0;path=/';
  } else {
    document.cookie = 'cookie_consent=;path=/;Max-Age=0';
  }
};

export const getCookieConsent = () => {
  const cookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)cookie_consent\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );

  return cookie === '1' ? true : (cookie === '0' ? false : null);
};

interface CookieConsentProviderProps {
  children: ReactNode;
}

const CookieConsentProvider = ({ children }: CookieConsentProviderProps) => {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsent);

  const acceptCookies = useCallback(() => {
    updateCookie(true);
    setCookieConsent(true);
  }, [setCookieConsent]);

  const denyCookies = useCallback(() => {
    updateCookie(false);
    setCookieConsent(false);
  }, [setCookieConsent]);

  // Update Analytics consent.
  useEffect(() => {
    window.gtag?.('consent', 'update', {
      ad_storage: cookieConsent ? 'granted' : 'denied',
      ad_user_data: 'denied',
      ad_personalization: cookieConsent ? 'granted' : 'denied',
      analytics_storage: 'granted',
    });
  }, [cookieConsent]);

  return (
    <CookieConsentContext.Provider value={{ cookieConsent, acceptCookies, denyCookies }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentProvider;
