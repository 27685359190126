
import { startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import useLocale from '../useLocale';

export interface DateRangeProps {
  start: string | null;
  end?: string | null;
}

/**
 * @param start UTC start date.
 * @param end UTC end date.
 */
const DateRange = ({ start, end }: DateRangeProps) => {
  const { parseDate, formatDate, locale, timezone } = useLocale();

  const startDate = startOfDay(utcToZonedTime(parseDate(start, { timezone: 'UTC' }), timezone));
  const endDate = startOfDay(utcToZonedTime(parseDate(end || start, { timezone: 'UTC' }), timezone));

  if (startDate.getTime() === endDate.getTime()) {
    return (
      <>
        {formatDate(startDate, { format: 'display_date', timezone: null })}
      </>
    );
  }

  try {
    const formatted = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric', // @ts-ignore-next-line
    }).formatRange(startDate, endDate);

    return <>{formatted}</>;
  } catch {
    return (
      <>
        {formatDate(startDate, { format: 'display_date', timezone: null })}
        {' - '}
        {formatDate(endDate, { format: 'display_date', timezone: null })}
      </>
    );
  }
};

export default DateRange;
